import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert,
  Card,
  CardContent,
  Grid,
  Modal,
  Select,
  MenuItem,
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NumbersIcon from '@mui/icons-material/Numbers';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { db } from '../firebase';
import { doc, setDoc, updateDoc, increment } from 'firebase/firestore';

// Colores centralizados
const COLORS = {
  primary: '#1976d2',
  secondary: '#115293',
  success: '#4caf50',
  warning: '#FFD700',
};

// Validación de números
const validateNumbers = (numbers) => {
  const numArray = numbers.split('-').map((num) => num.trim()).filter((num) => num !== '');
  return numArray.length === 3 && numArray.every((num) => !isNaN(Number(num)) && Number(num) > 0);
};

// Función para inicializar o actualizar el documento "Amonedas"
const updateFirestoreTotals = async (groupKey, amount) => {
  const docRef = doc(db, 'inversiones', 'Amonedas');
  try {
    await updateDoc(docRef, {
      [groupKey]: increment(amount),
      Itotalmonedas: increment(amount),
    });
  } catch (error) {
    if (error.code === 'not-found') {
      // Si el documento no existe, lo creamos
      const initialData = {
        grupo1: groupKey === 'grupo1' ? amount : 0,
        grupo2: groupKey === 'grupo2' ? amount : 0,
        Itotalmonedas: amount,
      };
      await setDoc(docRef, initialData);
    } else {
      console.error('Error al actualizar Firestore:', error);
    }
  }
};

// Componente para mostrar las inversiones de un usuario
const UserInvestment = ({ userLabel, numbers, investment, error, isSubmitted, publicInvestment, onChange }) => (
  <Card
    sx={{
      flex: 1,
      borderRadius: '16px',
      boxShadow: '0 6px 18px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    }}
  >
    <CardContent>
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: COLORS.primary, mb: 2 }}>
        {userLabel}
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {!isSubmitted ? (
        <>
          <TextField
            fullWidth
            label="Ingrese 3 números (ej: 12-34-56)"
            value={numbers}
            onChange={(e) => onChange('numbers', e.target.value)}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            type="number"
            label="Cantidad de monedas"
            value={investment}
            onChange={(e) => onChange('investment', e.target.value)}
            sx={{ mb: 3 }}
          />
          <Button
            variant="contained"
            onClick={() => onChange('submit')}
            sx={{
              backgroundColor: COLORS.primary,
              color: '#fff',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: COLORS.secondary },
              width: '100%',
            }}
          >
            Confirmar
          </Button>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            mt: 2,
            gap: 2,
            p: 2,
            backgroundColor: '#f7f9fc',
            borderRadius: '12px',
          }}
        >
          <CheckCircleOutlineIcon sx={{ fontSize: 50, color: COLORS.success }} />
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: COLORS.success }}>
            ¡Inversión registrada exitosamente!
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <NumbersIcon sx={{ color: COLORS.primary, fontSize: 30 }} />
            <Typography>
              <strong>Números seleccionados:</strong> {numbers}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <MonetizationOnIcon sx={{ color: COLORS.warning, fontSize: 30 }} />
            <Typography>
              <strong>Monedas invertidas:</strong> {investment}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
            <MonetizationOnIcon sx={{ color: COLORS.primary, fontSize: 30 }} />
            <Typography>
              <strong>Monedas aportadas por otros:</strong> {publicInvestment}
            </Typography>
          </Box>
        </Box>
      )}
    </CardContent>
  </Card>
);

const InvestPage = () => {
  const [state, setState] = useState({
    users: {
      user1: { numbers: '', investment: '', error: '', isSubmitted: false, publicInvestment: 0 },
      user2: { numbers: '', investment: '', error: '', isSubmitted: false, publicInvestment: 0 },
    },
    modalOpen: false,
    selectedUser: '',
    investmentAmount: '',
    modalError: '',
  });

  const [timeLeft, setTimeLeft] = useState(0); // Nuevo estado para el tiempo restante

  useEffect(() => {
    let timer;

    if (state.users.user1.isSubmitted) {
      // Calcular la hora objetivo de las 2:30 PM
      const now = new Date();
      let target = new Date();
      target.setHours(14, 30, 0, 0);

      if (now > target) {
        // Si ya pasó las 2:30 PM, usar el siguiente día
        target.setDate(target.getDate() + 1);
      }

      const timeDiff = Math.floor((target.getTime() - now.getTime()) / 1000);
      setTimeLeft(timeDiff); // Tiempo en segundos

      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [state.users.user1.isSubmitted]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${hrs}:${mins}:${secs}`;
  };

  const handleUserChange = (userKey, key, value) => {
    setState((prev) => {
      const updatedUser = { ...prev.users[userKey] };

      if (key === 'submit') {
        const { numbers, investment } = updatedUser;

        if (!validateNumbers(numbers)) {
          updatedUser.error = 'Debe ingresar 3 números válidos separados por guiones.';
          return { ...prev, users: { ...prev.users, [userKey]: updatedUser } };
        }

        if (Number(investment) <= 0) {
          updatedUser.error = 'La cantidad mínima de monedas debe ser mayor a 0.';
          return { ...prev, users: { ...prev.users, [userKey]: updatedUser } };
        }

        updatedUser.error = '';
        updatedUser.isSubmitted = true;

        updateFirestoreTotals(userKey === 'user1' ? 'grupo1' : 'grupo2', Number(investment));
        return { ...prev, users: { ...prev.users, [userKey]: updatedUser } };
      }

      updatedUser[key] = value;
      return { ...prev, users: { ...prev.users, [userKey]: updatedUser } };
    });
  };

  const handleInvestment = () => {
    if (!state.selectedUser || Number(state.investmentAmount) <= 0) {
      setState((prev) => ({ ...prev, modalError: 'Seleccione un usuario e ingrese una cantidad válida.' }));
      return;
    }

    setState((prev) => {
      const updatedUser = { ...prev.users[state.selectedUser] };
      updatedUser.publicInvestment += Number(state.investmentAmount);

      updateFirestoreTotals(
        state.selectedUser === 'user1' ? 'grupo1' : 'grupo2',
        Number(state.investmentAmount)
      );

      return {
        ...prev,
        users: { ...prev.users, [state.selectedUser]: updatedUser },
        modalOpen: false,
        modalError: '',
        investmentAmount: '',
        selectedUser: '',
      };
    });
  };

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', color: COLORS.primary, mb: 2 }}>
        Gana Invirtiendo
      </Typography>

      {/* Mostrar el contador regresivo */}
      {state.users.user1.isSubmitted && timeLeft > 0 && (
        <Typography variant="h5" sx={{ textAlign: 'center', color: COLORS.secondary, mb: 4 }}>
          Tiempo restante: {formatTime(timeLeft)}
        </Typography>
      )}

      <Grid container spacing={3}>
        {['user1', 'user2'].map((userKey) => (
          <Grid item xs={12} sm={6} key={userKey}>
            <UserInvestment
              userLabel={`Usuario ${userKey === 'user1' ? '1' : '2'}`}
              numbers={state.users[userKey].numbers}
              investment={state.users[userKey].investment}
              error={state.users[userKey].error}
              isSubmitted={state.users[userKey].isSubmitted}
              publicInvestment={state.users[userKey].publicInvestment}
              onChange={(key, value) => handleUserChange(userKey, key, value)}
            />
          </Grid>
        ))}
      </Grid>
      {state.users.user1.isSubmitted && state.users.user2.isSubmitted && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            onClick={() => setState((prev) => ({ ...prev, modalOpen: true }))}
            sx={{
              backgroundColor: COLORS.primary,
              color: '#fff',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: COLORS.secondary },
            }}
          >
            Invertir
          </Button>
        </Box>
      )}
      <Modal open={state.modalOpen} onClose={() => setState((prev) => ({ ...prev, modalOpen: false }))}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            borderRadius: '16px',
            boxShadow: 24,
            p: 4,
            width: '400px',
          }}
        >
          <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', color: COLORS.primary }}>
            Realizar Inversión
          </Typography>
          {state.modalError && <Alert severity="error" sx={{ mb: 2 }}>{state.modalError}</Alert>}
          <Select
            fullWidth
            value={state.selectedUser}
            onChange={(e) => setState((prev) => ({ ...prev, selectedUser: e.target.value }))}
            displayEmpty
            sx={{ mb: 3 }}
          >
            <MenuItem value="" disabled>Seleccione un Usuario</MenuItem>
            <MenuItem value="user1">Usuario 1</MenuItem>
            <MenuItem value="user2">Usuario 2</MenuItem>
          </Select>
          <TextField
            fullWidth
            type="number"
            label="Cantidad de monedas"
            value={state.investmentAmount}
            onChange={(e) => setState((prev) => ({ ...prev, investmentAmount: e.target.value }))}
            sx={{ mb: 3 }}
          />
          <Button
            variant="contained"
            onClick={handleInvestment}
            sx={{
              backgroundColor: COLORS.primary,
              color: '#fff',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: COLORS.secondary },
            }}
          >
            Confirmar
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default InvestPage;
