import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Chat from './components/Chat';
import Settings from './components/Settings';

import Predictions from './components/Predictions';
import MaintenancePage from './components/MaintenancePage';
import RecommendedNumbers from './components/RecommendedNumbers';
import CoinHistory from './components/CoinHistory'; // Ajusta la ruta si es necesario
import Dashboard from './components/Dashboard';
import WeeklyStatistics from './components/WeeklyStatistics.js';
import EditProfile from './components/EditProfile';
import Monitor from './components/Monitor';
import Subscribe from './components/Subscribe';
import HistorialChat from './components/historialchat';
import ConnectedUsers from './components/ConnectedUsers';
import InvestPage from './components/InvestPage';
import GranRifa from './components/GranRifa';
import AddParticipant from './components/AddParticipant';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/coin-history" element={<CoinHistory />} />
        
        <Route path="/predictions" element={<Predictions />} /> {/* Ruta para Predictions */}
        <Route path="/maintenance" element={<MaintenancePage />} />
        <Route path="/recommended" element={<RecommendedNumbers />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/WeeklyStatistics" element={<WeeklyStatistics />} />
        <Route path="/EditProfile" element={<EditProfile />} />
        <Route path="/Monitor" element={<Monitor />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/historial-chat" element={<HistorialChat />} />
        <Route path="/ConnectedUsers" element={<ConnectedUsers />} />
        <Route path="/InvestPage" element={<InvestPage />} />
        <Route path="/gran-rifa" element={<GranRifa />} />
        <Route path="/AddParticipant" element={<AddParticipant />} />

      </Routes>
    </Router>
  );
}

export default App;
