import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { Box, TextField, Button, Typography, Container, FormControlLabel, Checkbox, IconButton, Alert } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import SnowflakeIcon from '@mui/icons-material/AcUnit';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState('');
  const [maintenanceMessage, setMaintenanceMessage] = useState(false);
  const [forgotMode, setForgotMode] = useState(false);
  const [recoveredCredentials, setRecoveredCredentials] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedUsername = localStorage.getItem('rememberedUsername');
    const savedPassword = localStorage.getItem('rememberedPassword');

    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }

    const backgroundImages = [
      'https://i.pinimg.com/originals/8a/e0/0e/8ae00e610f195d92a1471c54df0c55ec.jpg',
      'https://watermark.lovepik.com/photo/50072/2231.jpg_wh1200.jpg',
      'https://i.pinimg.com/originals/a1/11/eb/a111eb6da88484397d8b0068ee4e524e.jpg',
      'https://e1.pxfuel.com/desktop-wallpaper/755/363/desktop-wallpaper-6-city-street-backgrounds-city-roads.jpg',
      'https://a.travel-assets.com/findyours-php/viewfinder/images/res40/74000/74993-Santiago.jpg',
      'https://xeliter.com/wp-content/uploads/2018/11/info-las-terrenas.jpg',
      'https://cdn-ikpfnej.nitrocdn.com/xLzWpHmRbWVSpfxjSQEAYTImyTCjelMC/assets/images/optimized/rev-c83bff3/sublimesamana.com/wp-content/uploads/2024/05/TOP-Header-1_Exclusividad.jpg',
    ];
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    setBackgroundImage(backgroundImages[randomIndex]);
  }, []);




  const handleRecoverCredentials = async () => {
    if (!phoneNumber.trim()) {
      setError('Por favor, ingresa un número de teléfono válido.');
      return;
    }
  
    try {
      setError('');
      setRecoveredCredentials(null);
  
      const usersQuery = query(
        collection(db, 'users'),
        where('phone', '==', phoneNumber.trim())
      );
  
      const usersSnapshot = await getDocs(usersQuery);
  
      if (usersSnapshot.empty) {
        setError('No se encontraron usuarios con este número de teléfono.');
        return;
      }
  
      const userDoc = usersSnapshot.docs[0].data();
      setRecoveredCredentials({
        username: userDoc.username,
        password: userDoc.password,
      });
    } catch (error) {
      console.error('Error al recuperar credenciales:', error);
      setError('Hubo un error al intentar recuperar tus credenciales.');
    }
  };
  

  const handleLogin = async (e) => {
    e.preventDefault();
    setMaintenanceMessage(false);
    setError('');

    try {
      const normalizedUsername = username.trim().toLowerCase();
      const userDocRef = doc(db, 'users', normalizedUsername);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        setError('El usuario no existe');
        return;
      }

      const userData = userDoc.data();

      if (userData.isBlocked) {
        setError(
          <>
            Tu cuenta temporal ha alcanzado el límite de accesos. 
            Te invitamos a registrarte como miembro permanente para disfrutar de todos los beneficios de nuestra Comunidad.
            <br />
            <Button
              variant="contained"
              color="success"
              sx={{ mt: 2 }}
              onClick={() => {
                const whatsappUrl = "https://wa.me/18098216633?text=Hola, mi cuenta ha sido bloqueada y necesito asistencia.";
                window.open(whatsappUrl, '_blank');
              }}
            >
              Contactar por WhatsApp
            </Button>
          </>
        );
        return;
      }


      

      // Verificar y actualizar el campo conNormal para usuarios "normal"
    if (userData.role === 'normal') {
      const currentCount = userData.conNormal || 0; // Si no existe, iniciar con 0
      const updatedCount = currentCount + 1;

      if (updatedCount >= 10) {
        // Bloquear usuario si llega a 10 inicios de sesión
        await updateDoc(userDocRef, {
          conNormal: updatedCount,
          isBlocked: true,
        });
        setError('Su cuenta ha sido bloqueada automáticamente por exceso de inicios de sesión.');
        return;
      } else {
        // Actualizar el conteo en Firestore
        await updateDoc(userDocRef, {
          conNormal: updatedCount,
        });
      }
    }

    // Proceso de mantenimiento para usuarios normales
    if (userData.role === 'normall') {
      setMaintenanceMessage(true);
    } else {
      if (rememberMe) {
        localStorage.setItem('rememberedUsername', normalizedUsername);
        localStorage.setItem('rememberedPassword', password);
      } else {
        localStorage.removeItem('rememberedUsername');
        localStorage.removeItem('rememberedPassword');
      }
      localStorage.setItem('username', normalizedUsername);
      navigate('/chat');
    }
  } catch (error) {
    console.error('Error logging in:', error);
    setError('Error al iniciar sesión. Inténtalo de nuevo.');
  }
};


  

  const handleWhatsAppClick = () => {
    const whatsappUrl = "https://wa.me/18098216633?text=3";
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Container
    maxWidth="sm"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box
      sx={{
        p: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
        position: 'relative',
      }}
    >
      <HolidayVillageIcon
        sx={{
          fontSize: 60,
          color: 'green',
          position: 'absolute',
          top: -30,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <Typography
        component="h1"
        variant="h5"
        sx={{
          color: '#d32f2f',
          fontFamily: 'Pacifico, cursive',
        }}
      >
        {forgotMode ? 'Recuperar Credenciales' : '¡Gracias por visitarnos!'}
      </Typography>

      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      {recoveredCredentials && (
        <Alert severity="success" sx={{ mt: 2 }}>
          <Typography variant="body1"><strong>Usuario:</strong> {recoveredCredentials.username}</Typography>
          <Typography variant="body1"><strong>Contraseña:</strong> {recoveredCredentials.password}</Typography>
        </Alert>
      )}

      {!forgotMode ? (
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Nombre de usuario"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
            sx={{ bgcolor: 'white', borderRadius: 1 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Contraseña"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ bgcolor: 'white', borderRadius: 1 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                sx={{ color: 'green' }}
              />
            }
            label="Recordar mis datos"
          />
<Box
  sx={{
    p: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 3,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  }}
>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
          
              mb: 1,
              bgcolor: '#d32f2f',
              color: 'white',
              '&:hover': { bgcolor: '#c62828' },
              fontWeight: 'bold',
            }}
          >
            Acceso
          </Button>

 <Button
    fullWidth
    variant="outlined"
    sx={{
      
      bgcolor: '#4caf50',
      color: 'white',
      '&:hover': { bgcolor: '#388e3c' },
    }}
    onClick={() => navigate('/')}
  >
    Pagina Principal
  </Button>
</Box>

        </Box>
      ) : (
        <Box sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Número de Teléfono"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={{ bgcolor: 'white', borderRadius: 1 }}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleRecoverCredentials}
            sx={{
              mt: 2,
              bgcolor: '#1976d2',
              color: 'white',
              '&:hover': { bgcolor: '#1565c0' },
            }}
          >
            Recuperar Credenciales
          </Button>
        </Box>
      )}

      <Button
        fullWidth
        variant="outlined"
        sx={{ mt: 2 }}
        onClick={() => setForgotMode(!forgotMode)}
      >
        {forgotMode ? 'Volver al Inicio de Sesión' : '¿Olvidaste tus Credenciales?'}
      </Button>
    </Box>
  </Container>
  );
}

export default Login;
