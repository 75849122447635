import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { db } from '../firebase';
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Alert,
  LinearProgress,
  Card,
  CardContent,
  Avatar,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const API_URL = 'https://sorteosrd.com/api/sorteosrd-results/217XUR6ivy8A1NdI4SKcnAa9YGZRV7w3';

const WeeklyStatistics = () => {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();


  // Función para verificar y reiniciar estadísticas
const checkResetTime = async () => {
  const now = new Date();
  if (now.getDay() === 1 && now.getHours() === 0 && now.getMinutes() === 1) {
    // Es lunes a las 12:01 AM
    await resetWeeklyStatistics();
  }
};

// Función para reiniciar las estadísticas
const resetWeeklyStatistics = async () => {
  try {
    const statsSnapshot = await getDocs(collection(db, 'weeklyStatistics'));

    for (const docRef of statsSnapshot.docs) {
      const statDoc = doc(db, 'weeklyStatistics', docRef.id);
      await setDoc(statDoc, { username: '', score: 0, aciertos: [] });
    }

    console.log('Datos de estadísticas semanales restablecidos.');
  } catch (err) {
    console.error('Error al restablecer estadísticas:', err);
  }
};

// Agregar un efecto para verificar el tiempo cada minuto
useEffect(() => {
  const intervalId = setInterval(checkResetTime, 60000);

  return () => clearInterval(intervalId); // Limpiar intervalo al desmontar el componente
}, []);

  const filteredLotteries = [
    'Florida Lottery 1:30 PM',
    'Florida 10:50 PM',
    'King Lottery 12:30 PM',
    'King Lottery 7:30 PM',
    'New York Lottery 3:30 PM',
    'New York Lottery 10:30 PM',
  ];

  const validDescriptions = [
    'Loto Real 12:55 PM',
    'La Suerte Dominicana 12:30PM',
    'La Suerte 6:00 PM',
    'La Primera 12:00PM',
    'La Primera 8:00 PM',
    'Quiniela Loteka 7:55 PM',
    'Quiniela LoteDom 5:55 PM',
    'Anguila 10:00 AM',
    'Anguila 1:00 PM',
    'Anguila 6:00 PM',
    'Anguila 9:00 PM',
    'Loteria Nacional 2:30 PM Gana Más',
  ];

  const getCurrentDateInRD = () => {
    const rdTime = new Date().toLocaleString('en-US', { timeZone: 'America/Santo_Domingo' });
    return rdTime.split(',')[0]; // Obtener solo la fecha
  };

  const normalizeDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { timeZone: 'America/Santo_Domingo' });
  };

  const fetchStatistics = async () => {
    try {
      setLoading(true);
      setError('');
      const currentDate = getCurrentDateInRD();

      const [weeklyStatsSnapshot, predictionsSnapshot, usersSnapshot] = await Promise.all([
        getDocs(collection(db, 'weeklyStatistics')),
        getDocs(collection(db, 'predictions')),
        getDocs(collection(db, 'users')),
      ]);

      const usersMap = usersSnapshot.docs.reduce((map, doc) => {
        map[doc.id] = doc.data().avatarUrl || '';
        return map;
      }, {});

      const savedStatistics = weeklyStatsSnapshot.docs.map((doc) => ({
        id: doc.id,
        username: doc.data().username,
        avatarUrl: usersMap[doc.data().username] || '',
        totalScore: doc.data().score || 0,
        aciertos: Array.isArray(doc.data().aciertos) ? doc.data().aciertos : [],
      }));

      const predictions = predictionsSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          username: doc.data().username,
          numbers: doc.data().numbers?.split('-').map(Number) || [],
          timestamp: normalizeDate(doc.data().timestamp?.toDate() || new Date()),
        }))
        .filter((prediction) => prediction.timestamp === currentDate);

      const apiResponse = await axios.get(API_URL);
      const apiResults = apiResponse.data
        .filter((result) => {
          const resultDate = normalizeDate(result.created_at);
          return (
            (filteredLotteries.includes(result.descripcion) || validDescriptions.includes(result.descripcion)) &&
            resultDate === currentDate
          );
        })
        .map((result) => {
          const isFilteredLottery = filteredLotteries.includes(result.descripcion);
          return {
            numeros: isFilteredLottery
              ? [result.num1.substring(1, 3), result.num2, result.num3].map(String)
              : [result.num1, result.num2, result.num3].map(String),
            descripcion: result.descripcion,
            created_at: normalizeDate(result.created_at),
          };
        });

      const combinedStats = calculateStatistics(savedStatistics, predictions, apiResults, usersMap);

      for (const stat of combinedStats) {
        const userDoc = doc(db, 'weeklyStatistics', stat.username);
        await setDoc(userDoc, {
          username: stat.username,
          score: stat.totalScore,
          aciertos: stat.aciertosToday,
        }, { merge: true });
      }

      const maxScore = Math.max(...combinedStats.map((stat) => stat.totalScore), 1.4);
      const statsWithProgress = combinedStats.map((stat) => ({
        ...stat,
        progress: (stat.totalScore / maxScore) * 100,
      }));
      const sortedStats = statsWithProgress.sort((a, b) => b.totalScore - a.totalScore);

      setStatistics(sortedStats);
    } catch (err) {
      console.error('Error al cargar estadísticas:', err);
      setError('No se pudieron cargar los datos. Inténtalo más tarde.');
    } finally {
      setLoading(false);
    }
  };

  const calculateStatistics = (savedStatistics, predictions, apiResults) => {
    const statsMap = {};
  
    // Inicializar el mapa con los usuarios existentes
    savedStatistics.forEach((stat) => {
      statsMap[stat.username] = { ...stat, dailyScore: 0, aciertosToday: [] };
    });
  
    predictions.forEach(({ username, numbers, timestamp: predictionDate }) => {
      let dailyScore = 0;
      const aciertosToday = [];
  
      // Crear una lista de números en string para comparación rápida
      const userNumbers = numbers.map((num) => ({
        original: num.toString(),
        flipped: num.toString().split('').reverse().join(''),
      }));
  
      apiResults.forEach(({ numeros, descripcion, created_at: apiDate }) => {
        // Si la predicción fue hecha después del resultado de la API, ignorarla
        if (new Date(predictionDate) > new Date(apiDate)) return;
  
        numeros.forEach((lotNum, index) => {
          userNumbers.forEach(({ original, flipped }) => {
            if (lotNum === original || lotNum === flipped) {
              // Determinar puntos según la posición
              let points = 0;
              if (index === 0) {
                points = 1; // Primera posición
              } else if (index === 1) {
                points = 0.75; // Segunda posición
              } else if (index === 2) {
                points = 0.5; // Tercera posición
              }
              if (lotNum === flipped) {
                points = 0.5; // Acierto volteado
              }
  
              const description = `${['En Primera', 'En Segunda', 'En Tercera'][index]}${
                lotNum === flipped ? ' (Volteado)' : ''
              }`;
  
              dailyScore += points;
              aciertosToday.push({ num: original, loteria: descripcion, description });
            }
          });
        });
      });
  
      // Actualizar el mapa de estadísticas
      if (!statsMap[username]) {
        statsMap[username] = { username, avatarUrl: '', totalScore: dailyScore, aciertosToday };
      } else {
        statsMap[username].totalScore += dailyScore;
        statsMap[username].aciertosToday.push(...aciertosToday);
      }
    });
  
    return Object.values(statsMap);
  };
  
  

  const getProgressColor = (progress) => {
    if (progress >= 75) return '#4caf50'; // Verde
    if (progress >= 50) return '#ffc107'; // Amarillo
    return '#f44336'; // Rojo
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" gutterBottom>
        Estadísticas Semanales
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : statistics.length === 0 ? (
        <Typography align="center" color="textSecondary">
          No hay estadísticas disponibles para mostrar.
        </Typography>
      ) : (
        <Box sx={{ display: 'grid', gap: 3 }}>
          {statistics.map((user, index) => (
           <Card
           key={user.id}
           sx={{
             border: `3px solid ${user.dailyScore > 0 ? 'green' : 'red'}`,
             borderRadius: 3,
             boxShadow: 3,
           }}
         >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Avatar src={user.avatarUrl || ''} alt={user.username} />
                  <Typography variant="h6">
                    #{index + 1} {user.username}
                  </Typography>
                </Box>
                <Typography variant="body2">Puntaje Total: {user.totalScore.toFixed(2)}</Typography>
                <Typography variant="body2">Aciertos de Hoy: {user.aciertosToday.length}</Typography>
                {user.aciertosToday.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Loterías con Aciertos (Hoy):
                    </Typography>
                    <ul>
                      {user.aciertosToday.map((acierto, i) => (
                        <li key={i}>
                          {acierto.loteria} - Número: {acierto.num} ({acierto.description})
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <Box sx={{ flexGrow: 1, position: 'relative' }}>
                    <LinearProgress
                      variant="determinate"
                      value={user.progress}
                      sx={{
                        height: 15,
                        borderRadius: 10,
                        backgroundColor: '#e0e0e0',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: getProgressColor(user.progress),
                          transition: 'width 0.4s ease',
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        color: '#000',
                      }}
                    >
                      {Math.round(user.progress)}%
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
      <Box
        sx={{
          mt: 5,
          display: 'flex',
          justifyContent: 'center',
          gap: 3,
          flexWrap: 'wrap',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/chat')}
          sx={{ px: 4, py: 1.5, fontWeight: 'bold', fontSize: '16px', borderRadius: '8px' }}
        >
          Volver al Chat
        </Button>
      
     
      </Box>
    </Container>
  );
};

export default WeeklyStatistics;