import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Container, Card, CardContent } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Helmet } from 'react-helmet';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

function Home() {
  const navigate = useNavigate();
  const username = localStorage.getItem('username');

  // Actualiza la última conexión del usuario en Firestore
  const updateLastOnline = async () => {
    if (username) {
      try {
        const userDocRef = doc(db, 'users', username);
        await updateDoc(userDocRef, {
          lastOnline: serverTimestamp(),
        });
      } catch (error) {
        console.error('Error al actualizar lastOnline:', error);
      }
    }
  };

  useEffect(() => {
    updateLastOnline();
  }, []);

  const handleSignUp = () => navigate('/signup');
  const handleLogin = () => navigate('/login');
  const handleYouTubeLink = () =>
    window.open('https://www.youtube.com/watch?v=0mNO1MEcu7E', '_blank');
  const handleWhatsAppContact = () =>
    window.open(
      'https://wa.me/18098216633?text=Hola, mi cuenta ha sido bloqueada y necesito asistencia.',
      '_blank'
    );

  return (
    <>
      <Helmet>
        <title>Chat SRD - Interactúa sobre Loterías Dominicanas</title>
        <meta
          name="description"
          content="Chat SRD es un chat interactivo para hablar sobre loterías dominicanas, interactuar libremente con otros jugadores, ver números recomendados y sorteos de loterías."
        />
        <meta
          name="keywords"
          content="chat SRD, loterías dominicanas, números recomendados, sorteos, jugadores, interactuar, chat interactivo"
        />
      </Helmet>

      <Container maxWidth="sm">
        <Box
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
          }}
        >
          {/* Logo */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <img
              src="https://chatsrd.com/logo.png"
              alt="Logo de Chat SRD"
              style={{
                width: '220px',
                height: 'auto',
                borderRadius: '12px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
              }}
            />

            
          </Box>
          

          {/* Tarjeta de Bienvenida */}
          <Card
            sx={{
              
              width: '100%',
              textAlign: 'center',
              borderRadius: '12px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#f5f5f5',
            }}
          >
            <CardContent>
              <Typography
                component="h1"
                variant="h5"
                sx={{ fontWeight: 'bold', mb: 1, color: '#1976d2' }}
              >
                ¡Bienvenido a la Comunidad de Loterías!
              </Typography>
              <Typography component="p" variant="body1" color="textSecondary">
                Regístrate o inicia sesión para interactuar con otros jugadores y
                acceder a nuestras predicciones exclusivas.
              </Typography>
            </CardContent>
          </Card>

          {/* Botones de Acción */}
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleSignUp}
              sx={{
                width: '140px',
                backgroundColor: '#4caf50',
                color: '#fff',
                fontWeight: 'bold',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#388e3c',
                },
              }}
            >
              Regístrate
            </Button>
            <Button
              variant="outlined"
              onClick={handleLogin}
              sx={{
                width: '140px',
                borderColor: '#4caf50',
                color: '#4caf50',
                fontWeight: 'bold',
                borderRadius: '8px',
                '&:hover': {
                  borderColor: '#388e3c',
                  color: '#388e3c',
                },
              }}
            >
              Acceso
            </Button>
             {/* Botón Gran Rifa  ojo                     luego de la rifa quitar este boton*/}
  <Button
    variant="contained"
    color="secondary"
    onClick={() => navigate('/gran-rifa')}
    sx={{
      px: 4,
      py: 1.5,
      fontWeight: 'bold',
      fontSize: '16px',
      borderRadius: '8px',
    }}
  >
    Gran Rifa
  </Button>
          </Box>

          {/* Botón para Ver Tutorial */}
          <Button
            variant="contained"
            startIcon={<YouTubeIcon />}
            onClick={handleYouTubeLink}
            sx={{
              width: '100%',
              backgroundColor: '#f44336',
              color: '#fff',
              fontWeight: 'bold',
              borderRadius: '8px',
              
              '&:hover': {
                backgroundColor: '#d32f2f',
              },
            }}
          >
            Ver Tutorial en YouTube
          </Button>

          {/* Botón de WhatsApp */}
          <Button
            variant="contained"
            startIcon={<WhatsAppIcon />}
            onClick={handleWhatsAppContact}
            sx={{
              width: '100%',
              backgroundColor: '#25d366',
              color: '#fff',
              fontWeight: 'bold',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#1ebe57',
              },
            }}
          >
            Contactar por WhatsApp
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default Home;
