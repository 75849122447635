import React, { useState, useEffect } from 'react';
import { query, collection, onSnapshot, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState('realTimeSummary');
  const [userRoleCounts, setUserRoleCounts] = useState({
    total: 0,
    normal: 0,
    oro: 0,
    moderador: 0,
    platino: 0,
  });
  const [yesterdayCounts, setYesterdayCounts] = useState({
    total: 0,
    normal: 0,
    oro: 0,
    moderador: 0,
    platino: 0,
  });
  const [topUsers, setTopUsers] = useState([]);
  const [topVIPDetails, setTopVIPDetails] = useState([]);
  const [recentNormalUsers, setRecentNormalUsers] = useState([]);
  const [recentReferrals, setRecentReferrals] = useState([]);
  const [systemData, setSystemData] = useState({ fondos: 0, totalFondos: 0 });




  useEffect(() => {
    const fetchSystemData = () => {
      const systemQuery = query(collection(db, 'sistema'));
  
      const unsubscribeSystem = onSnapshot(systemQuery, (snapshot) => {
        if (!snapshot.empty) {
          const data = snapshot.docs[0].data(); // Asume que hay un único documento en la colección
          setSystemData({
            fondos: data.fondos || 0,
            totalFondos: data.totalFondos || 0,
          });
        }
      });
  
      return () => unsubscribeSystem();
    };
  
    fetchSystemData();
  }, []);


  useEffect(() => {
    const fetchDashboardData = () => {
      setLoading(true);


      

      const usersQuery = query(collection(db, 'users'), orderBy('monOro', 'desc'));
      const vipDetailsQuery = query(collection(db, 'vipDetails'), orderBy('Cost', 'desc'));
      const recentNormalUsersQuery = query(
        collection(db, 'users'),
        orderBy('createdAt', 'desc'),
        limit(15)
      );
      const referralsQuery = query(
        collection(db, 'referrals'),
        orderBy('timestamp', 'desc'),
        limit(15)
      );

      const unsubscribeUsers = onSnapshot(usersQuery, (snapshot) => {
        const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const roles = { total: 0, normal: 0, oro: 0, moderador: 0, platino: 0 };
      users.forEach((user) => {
        const role = user.role?.toLowerCase() || 'normal';
        roles.total++;
        if (roles[role] !== undefined) {
          roles[role]++;
        }
      });

        setUserRoleCounts(roles);

        // Simulación de datos de ayer (en un entorno real, se pueden cargar desde otra colección)
        setYesterdayCounts({
          total: roles.total - 3, // Simular cambio
          normal: roles.normal - 2,
          oro: roles.oro + 1,
          moderador: roles.moderador,
          platino: roles.platino - 1,
        });

        setTopUsers(users);
      });

      

      const unsubscribeVIPDetails = onSnapshot(vipDetailsQuery, (snapshot) =>
        setTopVIPDetails(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
      );

      const unsubscribeRecentNormalUsers = onSnapshot(recentNormalUsersQuery, (snapshot) =>
        setRecentNormalUsers(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
      );

      const unsubscribeReferrals = onSnapshot(referralsQuery, (snapshot) =>
        setRecentReferrals(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
      );

      setLoading(false);

      

      return () => {
        unsubscribeUsers();
        
        unsubscribeVIPDetails();
        unsubscribeRecentNormalUsers();
        unsubscribeReferrals();
      };
    };

    fetchDashboardData();
  }, []);

  const renderChangeIcon = (current, yesterday) => {
    if (current > yesterday) {
      return <ArrowUpwardIcon color="success" />;
    }
    if (current < yesterday) {
      return <ArrowDownwardIcon color="error" />;
    }
    return null;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      );
    }

    switch (selectedMenu) {
      case 'realTimeSummary':
        return (
          

          <Box>
  <Typography variant="h5" gutterBottom sx={{ color: '#1976d2', mb: 2 }}>
    Fondos Disponibles 
  </Typography>
  <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3, flexWrap: 'wrap', gap: 2 }}>  

    {/* Fondos */}
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="body1">
        <strong>Fondos:</strong> RD$ {systemData.fondos.toLocaleString('es-DO', {
          minimumFractionDigits: 2,
        })}
      </Typography>
    </Box>

    {/* Total Fondos */}
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="body1">
        <strong>Total Fondos:</strong> RD$ {systemData.totalFondos.toLocaleString('es-DO', {
          minimumFractionDigits: 2,
        })}
      </Typography>
    </Box>
  </Box>
</Box>
        );
      

        case 'topUsers':
          return (
            <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Posición</strong></TableCell>
                    <TableCell><strong>Usuario</strong></TableCell>
                    <TableCell><strong>Monedas de Oro</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topUsers
                    .filter(user => user.monOro >= 1) // Mostrar solo usuarios con 20 o más monedas
                    .map((user, index) => (
                      <TableRow key={user.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.monOro}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          );

      case 'vipDetails':
        return (
          <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Posición</strong></TableCell>
                  <TableCell><strong>Usuario</strong></TableCell>
                  <TableCell><strong>Cost</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topVIPDetails.map((vip, index) => (
                  <TableRow key={vip.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{vip.username}</TableCell>
                    <TableCell>{vip.Cost}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );

      case 'recentUsers':
        return (
          <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Usuario</strong></TableCell>
                  <TableCell><strong>Email</strong></TableCell>
                  <TableCell><strong>Fecha de Registro</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentNormalUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.username || 'Usuario Desconocido'}</TableCell>
                    <TableCell>{user.email || 'Sin Email'}</TableCell>
                    <TableCell>{user.createdAt?.toDate().toLocaleDateString() || '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );

      case 'recentReferrals':
        return (
          <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Usuario Referido</strong></TableCell>
                  <TableCell><strong>Referido Por</strong></TableCell>
                  <TableCell><strong>Fecha de Registro</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentReferrals.map((referral) => (
                  <TableRow key={referral.id}>
                    <TableCell>{referral.referred || 'Usuario Desconocido'}</TableCell>
                    <TableCell>{referral.referrer || 'Desconocido'}</TableCell>
                    <TableCell>{referral.timestamp?.toDate().toLocaleDateString() || '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );

        case 'userRoleCounts':
  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ color: '#1976d2', mb: 2 }}>
        Conteo de Usuarios por Rol
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3, flexWrap: 'wrap', gap: 2 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">Total de Usuarios</Typography>
          <Typography variant="h4">{userRoleCounts.total}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">Usuarios Oro</Typography>
          <Typography variant="h4">{userRoleCounts.oro}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">Usuarios Normales</Typography>
          <Typography variant="h4">{userRoleCounts.normal}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">Moderadores</Typography>
          <Typography variant="h4">{userRoleCounts.moderador}</Typography>
        </Box>
      </Box>
    </Box>
  );


      default:
        return null;
    }
  };

  

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: '#1976d2' }}>
        Dashboard
      </Typography>

      <Box sx={{ display: 'flex', gap: 3, mt: 4 }}>
        {/* Menú Lateral */}
        <Box sx={{ width: '20%', backgroundColor: '#f9f9f9', borderRadius: '12px', p: 2, boxShadow: 3 }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton selected={selectedMenu === 'realTimeSummary'} onClick={() => setSelectedMenu('realTimeSummary')}>
                <ListItemText primary="Resumen en Tiempo Real" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton selected={selectedMenu === 'topUsers'} onClick={() => setSelectedMenu('topUsers')}>
                <ListItemText primary="Monedas por Usuarios" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton selected={selectedMenu === 'vipDetails'} onClick={() => setSelectedMenu('vipDetails')}>
                <ListItemText primary="Costo VIP" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton selected={selectedMenu === 'recentUsers'} onClick={() => setSelectedMenu('recentUsers')}>
                <ListItemText primary="Últimos Usuarios Registrados" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
  <ListItemButton selected={selectedMenu === 'userRoleCounts'} onClick={() => setSelectedMenu('userRoleCounts')}>
    <ListItemText primary="Conteo de Usuarios por Rol" />
    
  </ListItemButton>
</ListItem>
<Divider />
            <ListItem disablePadding>
              <ListItemButton selected={selectedMenu === 'recentReferrals'} onClick={() => setSelectedMenu('recentReferrals')}>
                <ListItemText primary="Últimos Usuarios Referidos" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>

        {/* Contenido Central */}
        <Box sx={{ flexGrow: 1, backgroundColor: '#fff', borderRadius: '12px', p: 4, boxShadow: 3 }}>
          {renderContent()}

          
        </Box>
        
      </Box>
    </Container>
  );
}

export default Dashboard;
