import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, query, orderBy, where, Timestamp, updateDoc, doc, limit } from 'firebase/firestore';
import { Box, Typography, List, ListItem, ListItemText, Avatar, Button, Paper, Badge } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SecurityIcon from '@mui/icons-material/Security';
import PersonIcon from '@mui/icons-material/Person';
import BlockIcon from '@mui/icons-material/Block';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';

// Función para calcular el tiempo transcurrido desde el último acceso
const timeSince = (timestamp) => {
  if (!timestamp) return 'Desconocido';

  const now = new Date();
  const diffInSeconds = Math.floor((now - timestamp.toDate()) / 1000);

  const intervals = [
    { label: 'día', seconds: 86400 },
    { label: 'hora', seconds: 3600 },
    { label: 'minuto', seconds: 60 },
    { label: 'segundo', seconds: 1 },
  ];

  for (const { label, seconds } of intervals) {
    const count = Math.floor(diffInSeconds / seconds);
    if (count > 0) {
      return `${count} ${label}${count !== 1 ? 's' : ''}`;
    }
  }
  return 'Hace un momento';
};

// Mapeo de roles a íconos
const roleIcons = {
  oro: <StarIcon style={{ color: 'gold' }} />,
  platino: <StarOutlineIcon style={{ color: 'lightblue' }} />,
  moderador: <SecurityIcon style={{ color: 'green' }} />,
  default: <PersonIcon style={{ color: 'gray' }} />,
};

const getRoleIcon = (role) => roleIcons[role] || roleIcons.default;

function ConnectedUsers() {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [mostActiveUser, setMostActiveUser] = useState(null);
  const [userConnections, setUserConnections] = useState({});
  const navigate = useNavigate();
  const role = localStorage.getItem('role'); // Obtener el rol del usuario actual

  useEffect(() => {
    const fourDaysAgo = Timestamp.fromDate(new Date(Date.now() - 4 * 24 * 60 * 60 * 1000));

    const q = query(
      collection(db, 'users'),
      where('lastOnline', '>=', fourDaysAgo),
      orderBy('lastOnline', 'desc'),
      limit(20)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const connectionCount = {};
        const users = snapshot.docs.map((doc) => {
          const userData = { id: doc.id, ...doc.data() };
          connectionCount[userData.username] = (connectionCount[userData.username] || 0) + 1;
          return userData;
        });

        setOnlineUsers(users);

        // Encontrar el usuario más activo
        const activeUser = Object.entries(connectionCount).reduce(
          (max, [username, count]) => (count > max.count ? { username, count } : max),
          { username: null, count: 0 }
        );

        setMostActiveUser(activeUser.username);
        setUserConnections(connectionCount);
      },
      (error) => {
        console.error('Error al obtener usuarios conectados:', error);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleBlockUser = async (username) => {
    if (window.confirm(`¿Estás seguro de que quieres bloquear al usuario ${username}?`)) {
      try {
        const userDocRef = doc(db, 'users', username);
        await updateDoc(userDocRef, { isBlocked: true, online: false });
        alert(`Usuario ${username} ha sido bloqueado.`);
      } catch (error) {
        console.error('Error al bloquear al usuario:', error);
        alert(`Hubo un error al bloquear al usuario: ${error.message}`);
      }
    }
  };

  return (
    <Box
      sx={{
        padding: 4,
        maxWidth: '800px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Paper elevation={5} sx={{ padding: 4, borderRadius: '15px', background: '#f9f9f9' }}>
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontWeight: 'bold',
            color: '#1976d2',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
          }}
        >
          Usuarios Conectados
        </Typography>
        <List sx={{ mt: 2 }}>
          {onlineUsers.length > 0 ? (
            onlineUsers.map((user) => (
              <ListItem
                key={user.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 2,
                  mb: 2,
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  '&:hover': { boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Avatar
                    src={user.avatarUrl}
                    alt={`${user.username} avatar`}
                    sx={{ width: 60, height: 60, border: '2px solid #1976d2' }}
                  />
                  <ListItemText
                    primary={
                      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                        {user.username} {getRoleIcon(user.role)}
                      </Typography>
                    }
                    secondary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {user.online ? (
                          <>
                            <FiberManualRecordIcon sx={{ fontSize: 12, color: 'green' }} />
                            <Typography variant="body2" sx={{ color: 'green' }}>
                              En línea
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="body2" sx={{ color: '#616161' }}>
                            Última conexión: {timeSince(user.lastOnline)}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </Box>
                {role === 'moderador' && !user.isBlocked && (
                  <Button
                    variant="outlined"
                    startIcon={<BlockIcon />}
                    color="error"
                    sx={{
                      borderRadius: '20px',
                      fontWeight: 'bold',
                      padding: '6px 12px',
                      textTransform: 'none',
                    }}
                    onClick={() => handleBlockUser(user.username)}
                  >
                    Bloquear
                  </Button>
                )}
              </ListItem>
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                color: '#757575',
                fontStyle: 'italic',
                mt: 2,
              }}
            >
              No hay usuarios conectados.
            </Typography>
          )}
        </List>

        {mostActiveUser && (
          <Box sx={{ mt: 3, textAlign: 'center', p: 2, borderRadius: '10px', backgroundColor: '#e3f2fd' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Usuario más activo: {mostActiveUser} con {userConnections[mostActiveUser]} conexiones.
            </Typography>
          </Box>
        )}
      </Paper>

      <Button
        variant="contained"
        startIcon={<NavigateBeforeIcon />}
        onClick={() => navigate('/chat')}
        sx={{
          alignSelf: 'center',
          padding: '10px 30px',
          borderRadius: '30px',
          backgroundColor: '#1976d2',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        Volver al Chat
      </Button>
    </Box>
  );
}

export default ConnectedUsers;
