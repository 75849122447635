import React, { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, TextField, Button, Typography, Container, Alert } from '@mui/material';
import { jsPDF } from 'jspdf';

function AddParticipant() {
  const [username, setUsername] = useState('');
  const [ticketCount, setTicketCount] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const generateRandomNumber = () => Math.floor(1000 + Math.random() * 9000); // Genera un número aleatorio de 4 dígitos

  const generateMultipleTickets = (count) => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [400, 200], // Tamaño personalizado del boleto
    });

    const currentDate = new Date().toLocaleDateString('es-ES');

    for (let i = 0; i < count; i++) {
      const randomNumber = generateRandomNumber();

      // Fondo del boleto dorado
      doc.setFillColor('#FFD700');
      doc.rect(0, 0, 400, 200, 'F'); // Fondo completo del boleto

      // Borde negro interno
      doc.setDrawColor('#000000');
      doc.setLineWidth(5);
      doc.rect(10, 10, 380, 180); // Rectángulo interno

      // Título principal del boleto
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(20);
      doc.setTextColor('#000000');
      doc.text('Boleto consultante', 200, 40, { align: 'center' });

      // Subtítulo adicional
      doc.setFontSize(16);
      doc.text('para la rifa de 50,000 pesos', 200, 60, { align: 'center' });

      // Línea divisoria
      doc.setDrawColor('#000000');
      doc.setLineWidth(1);
      doc.line(50, 70, 350, 70);

      // Información del boleto
      doc.setFontSize(14);
      doc.text(`Nombre: ${username}`, 50, 100);
      doc.text(`Número de Boleto: ${i + 1}`, 50, 120); // Boleto #1, #2, etc.
      doc.text(`Fecha: ${currentDate}`, 50, 140);

      // Código único del boleto
      doc.setFontSize(28);
      doc.setTextColor('#8B0000'); // Rojo oscuro para destacar
      doc.text(`${randomNumber}`, 350, 120, { align: 'center' });

      // Si no es el último boleto, agregar una nueva página
      if (i < count - 1) {
        doc.addPage();
      }
    }

    // Guardar el archivo PDF con todos los boletos
    doc.save(`Boletos_${username}.pdf`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username.trim() || !ticketCount.trim()) {
      setError('Por favor, complete todos los campos.');
      setSuccess('');
      return;
    }

    if (isNaN(ticketCount) || parseInt(ticketCount, 10) <= 0) {
      setError('La cantidad de boletos debe ser un número positivo.');
      setSuccess('');
      return;
    }

    try {
      setLoading(true);
      const count = parseInt(ticketCount, 10);

      // Registrar cada boleto en la base de datos
      for (let i = 0; i < count; i++) {
        await addDoc(collection(db, 'granRifa'), {
          username: username.trim(),
          ticket: i + 1,
          purchaseDate: Timestamp.fromDate(new Date()),
          code: generateRandomNumber(),
        });
      }

      setSuccess('¡Boletos agregados correctamente!');
      setError('');
      generateMultipleTickets(count); // Generar el PDF con los boletos
      setUsername('');
      setTicketCount('');
    } catch (err) {
      setError('Hubo un error al agregar los boletos. Inténtelo nuevamente.');
      console.error(err);
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h5"
        sx={{ textAlign: 'center', fontWeight: 'bold', mt: 4, mb: 2 }}
      >
        Agregar Participante
      </Typography>
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mt: 2,
          p: 3,
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <TextField
          label="Nombre de Usuario"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Cantidad de Boletos"
          value={ticketCount}
          onChange={(e) => setTicketCount(e.target.value)}
          fullWidth
          required
          type="number"
          sx={{ mb: 2 }}
          inputProps={{ min: 1 }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            fontWeight: 'bold',
            padding: '10px 20px',
            mt: 2,
          }}
          disabled={loading}
        >
          {loading ? 'Agregando...' : 'Agregar Boletos'}
        </Button>
      </Box>
    </Container>
  );
}

export default AddParticipant;
