import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  Timestamp,
  collection,
  addDoc,
  query,
  orderBy,
  where,
  getDocs,
} from 'firebase/firestore';
import {
  Container,
  Typography,
  Button,
  Alert,
  Box,
  Paper,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function HistorialChat() {
  const [hasAccess, setHasAccess] = useState(false);
  const [userCoins, setUserCoins] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const username = localStorage.getItem('username');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const userRef = doc(db, 'users', username);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const currentDate = new Date();
          const expirationDate = userData.expirationDate?.toDate() || null;

          setUserCoins(userData.monOro || 0);

          if (userData.paidAccess && expirationDate && expirationDate > currentDate) {
            setHasAccess(true);
            fetchChatHistory();
          } else {
            setHasAccess(false);
          }
        }
      } catch (error) {
        console.error('Error al verificar el acceso:', error);
        setErrorMessage('Hubo un error al verificar el acceso.');
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, [username]);

  const handlePayForAccess = async () => {
    if (userCoins < 2) {
      setErrorMessage('No tienes suficientes monedas para acceder. Necesitas 2 monedas.');
      return;
    }

    try {
      const userRef = doc(db, 'users', username);
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1); // Acceso válido por 1 día

      // Actualizar las monedas y el acceso del usuario
      await updateDoc(userRef, {
        monOro: userCoins - 2,
        paidAccess: true,
        expirationDate: Timestamp.fromDate(expirationDate),
      });

      // Registrar la transacción en CoinHistory
      await addDoc(collection(db, `users/${username}/CoinHistory`), {
        type: 'Debit',
        amount: 2,
        description: 'Pago por acceso al historial del chat',
        timestamp: serverTimestamp(),
      });

      setHasAccess(true);
      setUserCoins(userCoins - 2);
      setErrorMessage('');
      fetchChatHistory();
    } catch (error) {
      console.error('Error al procesar el pago:', error);
      setErrorMessage('Hubo un error al procesar el pago.');
    }
  };

  const fetchChatHistory = async () => {
    try {
      const fourDaysAgo = new Date();
      fourDaysAgo.setDate(fourDaysAgo.getDate() - 4);

      const messagesRef = collection(db, 'messages');
      const q = query(messagesRef, where('timestamp', '>=', fourDaysAgo), orderBy('timestamp', 'asc'));

      const snapshot = await getDocs(q);
      const loadedMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      setMessages(loadedMessages);
    } catch (error) {
      console.error('Error al cargar el historial del chat:', error);
      setErrorMessage('Hubo un error al cargar el historial del chat.');
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('es-ES', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const groupMessagesByDate = () => {
    const groupedMessages = {};
    messages.forEach((msg) => {
      const dateKey = msg.timestamp?.toDate().toDateString();
      if (!groupedMessages[dateKey]) {
        groupedMessages[dateKey] = [];
      }
      groupedMessages[dateKey].push(msg);
    });
    return groupedMessages;
  };

  if (loading) {
    return <Typography>Cargando...</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ fontWeight: 'bold', mb: 3, color: '#1976d2' }}
      >
        Historial del Chat
      </Typography>

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      {hasAccess ? (
        <Box>
          {Object.entries(groupMessagesByDate()).map(([date, msgs]) => (
            <Box key={date} sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: '#42a5f5',
                  color: 'white',
                  p: 1,
                  borderRadius: '8px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  mb: 2,
                }}
              >
                {formatDate(new Date(date))}
              </Typography>
              {msgs.map((msg) => (
                <Paper
                  key={msg.id}
                  sx={{
                    p: 2,
                    mb: 2,
                    borderRadius: '12px',
                    backgroundColor: msg.username === username ? '#e1bee7' : '#f3e5f5',
                    color: '#333',
                    borderLeft: msg.username === username ? '5px solid #8e24aa' : '5px solid #7b1fa2',
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {msg.username}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>{msg.text}</Typography>
                  {msg.imageUrl && (
                    <Box sx={{ mt: 1 }}>
                      <img
                        src={msg.imageUrl}
                        alt="Imagen compartida"
                        style={{
                          width: '100%',
                          maxHeight: '300px',
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  )}
                </Paper>
              ))}
              <Divider sx={{ my: 2 }} />
            </Box>
          ))}
        </Box>
      ) : (
        <Alert severity="info" sx={{ mb: 4 }}>
          Para acceder al historial del chat de los últimos 4 días, necesitas pagar <strong>2 monedas</strong>. Esta transacción se registrará en tu historial de monedas.
        </Alert>
      )}

      {!hasAccess && (
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePayForAccess}
            sx={{ fontWeight: 'bold', padding: '10px 20px', borderRadius: '8px' }}
          >
            Pagar 2 Monedas para Acceder
          </Button>
        </Box>
      )}

      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate('/chat')}
          sx={{ fontWeight: 'bold', borderRadius: '8px', padding: '10px 20px' }}
        >
          Volver al Chat
        </Button>
      </Box>
    </Container>
  );
}

export default HistorialChat;
