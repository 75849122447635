import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import {
  Container,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Subscribe() {
  const [selectedBank, setSelectedBank] = useState('');
  const detailsRef = useRef(null);
  const username = localStorage.getItem('username') || 'Usuario';
  const navigate = useNavigate();

  // Detalles de las cuentas bancarias
  const bankDetails = {
    banreservas: {
      name: 'Banreservas',
      accountNumber: '9600560725',
      holder: 'Marcos Valerio',
    },
    popular: {
      name: 'Banco Popular',
      accountNumber: '003-2345559933',
      holder: 'Marcos Valerio',
    },
    caribe: {
      name: 'Caribe Express',
      accountNumber: 'Envio',
      holder: 'Marcos Antonio Valerio Delgado',
    },
    western: {
      name: 'Western Union',
      accountNumber: 'Envio',
      holder: 'Ivania Maria Jaquez',
    },
  };

  // Manejar el cambio de banco seleccionado
  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };

  // Generar imagen de los detalles bancarios
  const handleGenerateImage = () => {
    if (detailsRef.current) {
      html2canvas(detailsRef.current).then((canvas) => {
        const link = document.createElement('a');
        link.download = 'bank-details.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
  };

  // Función para manejar clic en WhatsApp
  const handleWhatsAppClick = () => {
    const whatsappUrl = "https://wa.me/18098216633?text=Hola, estoy interesado en comprar monedas.";
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
      <Paper
        elevation={3}
        sx={{
          p: { xs: 3, md: 5 },
          borderRadius: '12px',
          background: 'linear-gradient(135deg, #e3f2fd, #ffffff)',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Mensaje de introducción */}
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: '#1976d2',
            mb: 3,
          }}
        >
          ¡Ahora puedes obtener monedas y apoyar a quienes se esfuerzan por compartir predicciones diarias!
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, color: '#555', lineHeight: 1.8 }}>
          Además, podrás adquirir módulos para personalizar tu perfil, acceder a numerosas herramientas exclusivas y mucho más, todo esto usando tus monedas. Obtener monedas es muy fácil, solo sigue los pasos indicados.
        </Typography>

        {/* Selector de banco */}
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="bank-account-label">Selecciona una cuenta</InputLabel>
          <Select labelId="bank-account-label" value={selectedBank} onChange={handleBankChange}>
            <MenuItem value="banreservas">Banreservas</MenuItem>
            <MenuItem value="popular">Banco Popular</MenuItem>
            <MenuItem value="caribe">Caribe Express</MenuItem>
            <MenuItem value="western">Western Union</MenuItem>
          </Select>
        </FormControl>

        {/* Mostrar detalles de la cuenta seleccionada */}
        {selectedBank && (
          <Box
            ref={detailsRef}
            sx={{
              mt: 3,
              p: 3,
              backgroundColor: '#f1f1f1',
              borderRadius: '8px',
              textAlign: 'left',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant="body1">
              <strong>Banco:</strong> {bankDetails[selectedBank].name}
            </Typography>
            <Typography variant="body1">
              <strong>Número de cuenta:</strong> {bankDetails[selectedBank].accountNumber}
            </Typography>
            <Typography variant="body1">
              <strong>Titular:</strong> {bankDetails[selectedBank].holder}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              <strong>Usuario:</strong> {username}
            </Typography>
          </Box>
        )}

        {/* Información adicional */}
        <Typography sx={{ mt: 3 }}>
          Al realizar el depósito, asegúrate de incluir una nota con tu nombre de usuario:{' '}
          <strong style={{ color: '#1976d2' }}>{username}</strong>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Recuerda que <strong>1 moneda equivale a 100 pesos dominicanos</strong>. Establece el monto a depositar acorde a la cantidad de monedas que deseas comprar.
        </Typography>

        {/* Botón para generar imagen */}
        {selectedBank && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGenerateImage}
            sx={{ mt: 4, px: 4, py: 1, fontSize: '16px', borderRadius: '8px' }}
          >
            Generar Imagen
          </Button>
        )}

       
      </Paper>

      {/* Botón para regresar al chat */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/chat')}
        sx={{
          mt: 4,
          px: 4,
          py: 1,
          fontSize: '16px',
          fontWeight: 'bold',
          borderRadius: '8px',
          backgroundColor: '#3f51b5',
          '&:hover': {
            backgroundColor: '#303f9f',
          },
        }}
      >
        Volver al Chat
      </Button>

       {/* Botón de WhatsApp */}
       <Button
          variant="contained"
          onClick={handleWhatsAppClick}
          sx={{
            mt: 4,
            px: 4,
            py: 1,
            backgroundColor: '#25D366',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#1ebe57',
            },
          }}
        >
          WhatsApp
        </Button>

      {/* Reproductor de YouTube */}
      <Box
        sx={{
          mt: 5,
          position: 'relative',
          paddingBottom: '56.25%',
          height: 0,
          overflow: 'hidden',
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/uvEzn8Dz69w"
          title="Cómo hacer el depósito a su cuenta"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        ></iframe>
      </Box>
    </Container>
  );
}

export default Subscribe;
