import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import {
  Box,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function GranRifa() {
  const [participants, setParticipants] = useState([]);
  const [countdown, setCountdown] = useState('');
  const navigate = useNavigate();

  // Fetch and process participants from Firestore
  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'granRifa'));
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Agrupar boletos por usuario
        const groupedData = data.reduce((acc, participant) => {
          const username = participant.username || 'Usuario desconocido';
          const purchaseDate = new Date(participant.purchaseDate.seconds * 1000).toLocaleDateString(
            'es-ES'
          );

          if (!acc[username]) {
            acc[username] = { username, ticketCount: 0, purchaseDates: [] };
          }

          acc[username].ticketCount += 1; // Incrementar la cantidad de boletos
          if (!acc[username].purchaseDates.includes(purchaseDate)) {
            acc[username].purchaseDates.push(purchaseDate); // Agregar la fecha si no está incluida
          }
          return acc;
        }, {});

        // Convertir en un arreglo y ordenar por cantidad de boletos (descendente)
        const sortedData = Object.values(groupedData).sort(
          (a, b) => b.ticketCount - a.ticketCount
        );

        setParticipants(sortedData);
      } catch (error) {
        console.error('Error fetching participants:', error);
      }
    };

    fetchParticipants();
  }, []);

  // Countdown logic
  useEffect(() => {
    const targetDate = new Date('2025-01-20T21:30:00');
    const interval = setInterval(() => {
      const now = new Date();
      const timeLeft = targetDate - now;

      if (timeLeft <= 0) {
        clearInterval(interval);
        setCountdown('¡La Gran Rifa ha comenzado!');
      } else {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);

        setCountdown(
          `${days} días, ${hours} horas, ${minutes} minutos, ${seconds} segundos`
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleWhatsappClick = () => {
    const whatsappUrl =
      'https://wa.me/18098216633?text=Hola,%20quiero%20saber%20cómo%20puedo%20participar%20en%20la%20rifa.';
    window.open(whatsappUrl, '_blank');
  };

  const handleHomeRedirect = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="md">
      <Typography
        variant="h4"
        sx={{ textAlign: 'center', fontWeight: 'bold', mt: 4, mb: 2 }}
      >
        Gran Rifa
      </Typography>

      {/* Countdown */}
      <Box
        sx={{
          textAlign: 'center',
          mb: 4,
          p: 2,
          border: '2px solid #4caf50',
          borderRadius: '12px',
          backgroundColor: '#e8f5e9',
          color: '#1b5e20',
          fontSize: '20px',
          fontWeight: 'bold',
        }}
      >
        Tiempo restante para la rifa: {countdown}
      </Box>

      {/* WhatsApp Button */}
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Button
          onClick={handleWhatsappClick}
          variant="contained"
          sx={{
            backgroundColor: '#25D366',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '16px',
            padding: '10px 20px',
            '&:hover': { backgroundColor: '#1ebe5d' },
          }}
        >
          Solicitar información por WhatsApp
        </Button>
      </Box>

      {/* Highlighted Text */}
      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          background: 'linear-gradient(90deg, #ffe082, #ffd54f)',
          color: '#1b5e20',
          padding: '20px',
          borderRadius: '12px',
          fontWeight: 'bold',
          mb: 4,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          border: '2px solid #f57f17',
          fontSize: '18px',
          lineHeight: '1.6',
          wordWrap: 'break-word',
        }}
      >
        🎉 <strong>¡Aprovecha esta gran oportunidad! </strong> Inscríbete en nuestra Comunidad por
        tan solo <strong>500 pesos</strong> y obtén un boleto para participar en nuestra gran rifa
        de <strong>50,000 pesos</strong>. 📅 El sorteo se llevará a cabo en vivo el{' '}
        <strong>lunes 20 de enero a las 9:30 de la noche</strong>. ✨{' '}
        <span style={{ color: '#d32f2f' }}>¡Conéctate, participa y gana!</span> ✨
      </Typography>

      {/* Tabla */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          mb: 2,
          color: '#1b5e20',
          textTransform: 'uppercase',
        }}
      >
        Usuarios que han comprado sus boletos
      </Typography>
      <Table>
  <TableHead>
    <TableRow sx={{ backgroundColor: '#4caf50' }}>
      <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Usuario</TableCell>
      <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Cantidad de Boletos</TableCell>
      <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Fechas de Compra</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {participants.length > 0 ? (
      participants.map((participant) => (
        <TableRow
          key={participant.username}
          sx={{
            '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
            '&:nth-of-type(even)': { backgroundColor: '#e8f5e9' },
            '&:hover': { backgroundColor: '#d7ffd9', cursor: 'pointer' },
          }}
        >
          <TableCell sx={{ fontWeight: 'bold' }}>{participant.username}</TableCell>
          <TableCell>{participant.ticketCount}</TableCell>
          <TableCell>{participant.purchaseDates.join(', ')}</TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell
          colSpan={3}
          sx={{ textAlign: 'center', fontStyle: 'italic', color: '#757575' }}
        >
          No hay participantes registrados aún.
        </TableCell>
      </TableRow>
    )}
  </TableBody>
</Table>

      {/* Home Button */}
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
          onClick={handleHomeRedirect}
          variant="contained"
          sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '16px',
            padding: '10px 20px',
            '&:hover': { backgroundColor: '#115293' },
          }}
        >
          Volver a la página principal
        </Button>
      </Box>
    </Container>
  );
}

export default GranRifa;
